import React, { useEffect, useState } from 'react';
import styles from './EmailsForm.module.css';
import { Button, TextInput } from 'evergreen-ui';

interface EmailsFormProps {
    initialEmails: string;
    emailsDisabled: boolean;
    onEmailsChange: (emails: string, isValid: boolean) => void;
    setToggle: (toggleStatus: boolean) => void;
}

export const EmailsForm = ({
    initialEmails,
    emailsDisabled,
    onEmailsChange,
    setToggle,
}: EmailsFormProps) => {
    const [emails, setEmails] = useState<string[]>(['']);
    const [emailValidity, setEmailValidity] = useState<boolean[]>([true]);

    const validateEmails = (emailList: string[]) => {
        const validityList = emailList.map((email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        });
        setEmailValidity(validityList);
        const isAllValid = validityList.every((valid) => valid);
        onEmailsChange(emailList.join(',').trim(), isAllValid);
    };

    useEffect(() => {
        if (initialEmails) {
            const parsedEmails = initialEmails
                .split(',')
                .map((email) => email.trim());
            setEmails(parsedEmails);
            validateEmails(parsedEmails);

            if (!parsedEmails.length) {
                setToggle(false);
            }
        }
    }, [initialEmails]);

    const handleEmailChange = (index: number, value: string) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = value.trim().toLowerCase();
        setEmails(updatedEmails);
        validateEmails(updatedEmails);
    };

    const handleAddEmail = () => {
        if (emails.length < 10) {
            setEmails([...emails, '']);
            setEmailValidity([...emailValidity, true]);
        }
    };

    const handleRemoveEmail = (index: number) => {
        const updatedEmails = [...emails];
        updatedEmails.splice(index, 1);
        setEmails(updatedEmails);
        setEmailValidity(emailValidity.filter((_, i) => i !== index));
        validateEmails(updatedEmails);
        if (!updatedEmails.length) {
            setToggle(false);
        }
    };

    return (
        <div>
            {emails.map((email, index) => (
                <div key={index} className={styles.container}>
                    <div className={styles.emailContainer}>
                        <div style={{ width: '99%' }}>
                            <TextInput
                                type="email"
                                value={email}
                                disabled={emailsDisabled}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => handleEmailChange(index, e.target.value)}
                                placeholder="Enter email"
                                className={styles.emailInput}
                                style={{
                                    width: '100%',
                                    borderColor: !emailsDisabled
                                        ? emailValidity[index] && email
                                            ? '#00a5ac'
                                            : emailValidity[index] && !email
                                            ? '#d8dae5'
                                            : '#B85068FF'
                                        : '#d8dae5',
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                disabled={emailsDisabled}
                                type="button"
                                onClick={() => handleRemoveEmail(index)}
                                className={styles.removeButton}>
                                -
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
            <Button
                disabled={emailsDisabled || emails.length === 10}
                type="button"
                onClick={handleAddEmail}
                className={styles.addButton}>
                +
            </Button>
        </div>
    );
};
