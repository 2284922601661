import {
    AssessmentSection,
    Card,
    CardHeader,
    CardsWrapper,
    CardTitle,
    CheckMarkIcon,
    Desc,
    DetailsBox,
    EditIconWrapper,
    ForthStepWrapper,
    IconsWrapper,
    InfoIcon,
    InfoIconWrapper,
    InfoTooltip,
    InputBox,
    ReviewSection,
    ScanSettingSection,
    ScanSettingWrapper,
    Title,
    TitleWrapper,
    UploadTitle,
    Value,
    VideoPreviewWrapper,
    VideoWrapper,
} from './style';
import {
    ButtonWrapper,
    PrevButton,
    ScanSetupFooterWrapper,
    ScanSetupHeaderTitle,
    ScanSetupHeaderTitleWrapper,
} from '../../../style';
import AppButton from '../../../../../components/AppButton/AppButton';
import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import ScanStepsFlow from '../../ScanStepsFlow';
import { EditIcon, Switch, TickIcon } from 'evergreen-ui';
import ScanSetupModals from '../../ScanSetupModals/ScanSetupModals';
import { CreateScanForthStepProps, ScanLanguage } from '../../../../../typings';
import { AddTargetGroupStateSchema } from '../../../ScanSetupView';
import { EmailsForm } from '../../../../../components/EmailsForm/EmailsForm';

const ForthStep = (props: CreateScanForthStepProps) => {
    const {
        goToNextStep,
        goToPrevStep,
        isCognitiveAbilitiesEnabled,
        isSpeakingEnabled,
        isCultureFitVideoEnabled,
        speakingWrittenQuestions,
        cultureFitVideo,
        speakingVideo1,
        speakingVideo2,
        cultureFitIsCompleted,
        targetGroupName,
        setTargetGroupName,
        description,
        setDescription,
        language,
        setLanguage,
        scanName,
        setScanName,
        targetGroup,
        setTargetGroup,
        newAssessmentSection,
        assessment,
        hasCultureFit,
        hasSpeaking,
        hasCognitiveAbilities,
        hasCultureFitVideo,
        hasSpeakingVideo1,
        hasSpeakingVideo2,
        hasCultureFitQuestionnaire,
        isScanSetupWizard,
        isInternal,
        scanCompletionNotification,
        setScanCompletionNotification,
        scanCompletionNotificationEmails,
        setScanCompletionNotificationEmails,
        reportGenerationNotification,
        setReportGenerationNotification,
        reportGenerationNotificationEmails,
        setReportGenerationNotificationEmails,
        isScanCompletionNotificationEmailsValid,
        setScanCompletionNotificationEmailsValid,
        isReportGenerationNotificationEmailsValid,
        setReportGenerationNotificationEmailsValid,
    } = props;

    const [shouldShowChangeLanguageModal, showChangeLanguageModal] =
        useState<boolean>(false);
    const [
        shouldShowChangeTargetGroupNameModal,
        showChangeTargetGroupNameModal,
    ] = useState<boolean>(false);
    const [shouldShowChangeDescriptionModal, showChangeDescriptionModal] =
        useState<boolean>(false);
    const [shouldShowChangeAssessmentNameModal, showChangeAssessmentNameModal] =
        useState<boolean>(false);
    const [tGName, setTGName] = useState<string>(targetGroupName);
    const [assessmentName, setAssessmentName] = useState<string>(scanName);
    const [editedDescription, setEditedDescription] =
        useState<string>(description);
    const [editedLanguage, setEditedLanguage] = useState<string>(language);

    const handleUpdateTargetGroup = (
        key:
            | 'name'
            | 'assessment_id'
            | 'is_public'
            | 'auto_send_reports'
            | 'verify_emails',
        value: boolean,
    ) => {
        if (key === 'is_public' && !value) {
            setTargetGroup({
                ...targetGroup,
                is_public: false,
                verify_emails: false,
            } as AddTargetGroupStateSchema);
        } else
            setTargetGroup({
                ...targetGroup,
                [key]: value,
            } as AddTargetGroupStateSchema);
    };
    const changeConfirmed = () => {
        showChangeLanguageModal(false);
        showChangeTargetGroupNameModal(false);
        showChangeDescriptionModal(false);
        showChangeAssessmentNameModal(false);
        setLanguage(editedLanguage as ScanLanguage);
        setTargetGroupName(tGName);
        setDescription(editedDescription);
        setScanName(assessmentName);
    };
    const discardModals = () => {
        showChangeLanguageModal(false);
        showChangeTargetGroupNameModal(false);
        showChangeDescriptionModal(false);
        showChangeAssessmentNameModal(false);
        setEditedLanguage(language);
        setTGName(targetGroupName);
        setEditedDescription(description);
        setAssessmentName(scanName);
    };

    const handleScanCompletionEmailsChange = (
        emails: string,
        isValid: boolean,
    ) => {
        setScanCompletionNotificationEmails(emails);
        setScanCompletionNotificationEmailsValid(
            isValid || (!scanCompletionNotification && emails === ''),
        );
    };
    const handleReportGenerationEmailsChange = (
        emails: string,
        isValid: boolean,
    ) => {
        setReportGenerationNotificationEmails(emails);
        setReportGenerationNotificationEmailsValid(
            isValid || (!reportGenerationNotification && emails === ''),
        );
    };
    const onChangeScanCompletionNotification: ChangeEventHandler<
        HTMLInputElement
    > = (event) => {
        setScanCompletionNotification(event.target.checked);
    };
    const onChangeReportGenerationNotification: ChangeEventHandler<
        HTMLInputElement
    > = (event) => {
        setReportGenerationNotification(event.target.checked);
    };

    return (
        <>
            <ScanSetupHeaderTitleWrapper>
                <ScanSetupHeaderTitle>Create a new scan</ScanSetupHeaderTitle>
                <AppButton
                    isDisabled={
                        isScanSetupWizard &&
                        ((scanCompletionNotification &&
                            !isScanCompletionNotificationEmailsValid) ||
                            (reportGenerationNotification &&
                                !isReportGenerationNotificationEmailsValid))
                    }
                    onClick={goToNextStep}>
                    {isInternal
                        ? 'Create Internal Group'
                        : 'Create Candidate Group'}
                </AppButton>
            </ScanSetupHeaderTitleWrapper>
            <ScanStepsFlow
                isScanSetupWizard={isScanSetupWizard}
                active={['first', 'second', 'third', 'forth']}
                existingAssessmentSection={
                    isScanSetupWizard ? !newAssessmentSection : undefined
                }
            />
            <ForthStepWrapper>
                {isScanSetupWizard && (
                    <ReviewSection>
                        <Title>Review and configure your scan</Title>
                        <DetailsBox>
                            <Title className="small">Group name</Title>
                            <Value>{targetGroupName}</Value>
                            <EditIconWrapper
                                onClick={() =>
                                    showChangeTargetGroupNameModal(true)
                                }>
                                <EditIcon />
                            </EditIconWrapper>
                            <Title className="small">Description</Title>
                            <Value>{description}</Value>
                            {newAssessmentSection && (
                                <EditIconWrapper
                                    onClick={() =>
                                        showChangeDescriptionModal(true)
                                    }>
                                    <EditIcon />
                                </EditIconWrapper>
                            )}
                            {!newAssessmentSection && <div></div>}
                        </DetailsBox>
                    </ReviewSection>
                )}
                {isScanSetupWizard && (
                    <AssessmentSection>
                        <DetailsBox className="assessment">
                            <Title>Scan details</Title>
                            <InfoIconWrapper>
                                <InfoIcon>?</InfoIcon>
                                <InfoTooltip>Details of your scan</InfoTooltip>
                            </InfoIconWrapper>
                            <EditIconWrapper
                                style={{ cursor: 'auto' }}></EditIconWrapper>
                            <Title className="small">Scan name</Title>
                            <Value>
                                {newAssessmentSection
                                    ? scanName
                                    : assessment.name}
                            </Value>
                            {newAssessmentSection && (
                                <EditIconWrapper
                                    onClick={() =>
                                        showChangeAssessmentNameModal(true)
                                    }>
                                    <EditIcon />
                                </EditIconWrapper>
                            )}
                            {!newAssessmentSection && <div></div>}
                            <Title className="small">Language</Title>
                            {!newAssessmentSection && (
                                <Value>
                                    {assessment.language === 'en-US'
                                        ? 'English'
                                        : 'Dutch'}
                                </Value>
                            )}
                            {newAssessmentSection && (
                                <Value>
                                    {language === 'en-US' ? 'English' : 'Dutch'}
                                </Value>
                            )}
                            {newAssessmentSection && (
                                <EditIconWrapper
                                    onClick={() =>
                                        showChangeLanguageModal(true)
                                    }>
                                    <EditIcon />
                                </EditIconWrapper>
                            )}
                            {!newAssessmentSection && <div></div>}
                        </DetailsBox>
                        <CardsWrapper>
                            {newAssessmentSection && cultureFitIsCompleted && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Cultural Fit</CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    {!!cultureFitVideo && (
                                        <VideoWrapper>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={cultureFitVideo.url}>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {cultureFitVideo.file?.name
                                                        ? cultureFitVideo.file
                                                              ?.name
                                                        : cultureFitVideo.url.substring(
                                                              cultureFitVideo.url.lastIndexOf(
                                                                  '/',
                                                              ) + 1,
                                                          )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                        </VideoWrapper>
                                    )}
                                    {(!cultureFitVideo ||
                                        !isCultureFitVideoEnabled) && (
                                        <VideoWrapper>
                                            Your candidates will get a
                                            questionnaire to learn more about
                                            their work preferences.
                                        </VideoWrapper>
                                    )}
                                </Card>
                            )}
                            {!newAssessmentSection && !!hasCultureFit && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Cultural Fit</CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    {!!hasCultureFitVideo && (
                                        <VideoWrapper>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={
                                                        hasCultureFitVideo
                                                            .videos?.low_res
                                                    }>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {hasCultureFitVideo?.videos?.low_res.substring(
                                                        hasCultureFitVideo?.videos?.low_res.lastIndexOf(
                                                            '/',
                                                        ) + 1,
                                                    )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                        </VideoWrapper>
                                    )}
                                    {!!hasCultureFitQuestionnaire &&
                                        !hasCultureFitVideo && (
                                            <VideoWrapper>
                                                Your candidates will get a
                                                questionnaire to learn more
                                                about their work preferences.
                                            </VideoWrapper>
                                        )}
                                </Card>
                            )}
                            {newAssessmentSection && isSpeakingEnabled && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Speaking</CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    {!speakingWrittenQuestions && (
                                        <VideoWrapper>
                                            <UploadTitle className="speaking-title">
                                                Question 1: - Why do you want to
                                                work for this company?
                                            </UploadTitle>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={speakingVideo1?.url}>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {speakingVideo1?.file?.name
                                                        ? speakingVideo1?.file
                                                              ?.name
                                                        : speakingVideo1?.url.substring(
                                                              speakingVideo1.url.lastIndexOf(
                                                                  '/',
                                                              ) + 1,
                                                          )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                            <UploadTitle className="speaking-title">
                                                Question 2: - What can you bring
                                                to the company in your role?
                                            </UploadTitle>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={speakingVideo2?.url}>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {speakingVideo2?.file?.name
                                                        ? speakingVideo2.file
                                                              ?.name
                                                        : speakingVideo2?.url.substring(
                                                              speakingVideo2.url.lastIndexOf(
                                                                  '/',
                                                              ) + 1,
                                                          )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                        </VideoWrapper>
                                    )}
                                    {speakingWrittenQuestions && (
                                        <VideoWrapper>
                                            <Title className="small">
                                                Your candidates will see the
                                                following two questions:
                                            </Title>
                                            <div className="written-desc">
                                                1) Why do you want to work for
                                                this company?
                                                <br />
                                                2) What can you bring to the
                                                company in your role?
                                            </div>
                                        </VideoWrapper>
                                    )}
                                </Card>
                            )}
                            {!newAssessmentSection && !!hasSpeaking && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Speaking</CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    {!!hasSpeakingVideo1 &&
                                        !!hasSpeakingVideo2 && (
                                            <VideoWrapper>
                                                <UploadTitle className="speaking-title">
                                                    Question 1: - Why do you
                                                    want to work for this
                                                    company?
                                                </UploadTitle>
                                                <VideoPreviewWrapper>
                                                    <video
                                                        width={'50%'}
                                                        controls
                                                        src={
                                                            hasSpeakingVideo1?.low_res
                                                        }>
                                                        Your browser does not
                                                        support the video tag.
                                                    </video>
                                                    <UploadTitle className="video-title">
                                                        {hasSpeakingVideo1?.low_res.substring(
                                                            hasSpeakingVideo1?.low_res.lastIndexOf(
                                                                '/',
                                                            ) + 1,
                                                        )}
                                                    </UploadTitle>
                                                </VideoPreviewWrapper>
                                                <UploadTitle className="speaking-title">
                                                    Question 2: - What can you
                                                    bring to the company in your
                                                    role?
                                                </UploadTitle>
                                                <VideoPreviewWrapper>
                                                    <video
                                                        width={'50%'}
                                                        controls
                                                        src={
                                                            hasSpeakingVideo2?.low_res
                                                        }>
                                                        Your browser does not
                                                        support the video tag.
                                                    </video>
                                                    <UploadTitle className="video-title">
                                                        {hasSpeakingVideo2?.low_res.substring(
                                                            hasSpeakingVideo2?.low_res.lastIndexOf(
                                                                '/',
                                                            ) + 1,
                                                        )}
                                                    </UploadTitle>
                                                </VideoPreviewWrapper>
                                            </VideoWrapper>
                                        )}
                                    {!hasSpeakingVideo1 && !hasSpeakingVideo2 && (
                                        <VideoWrapper>
                                            <Title className="small">
                                                Your candidates will see the
                                                following two questions:
                                            </Title>
                                            <div className="written-desc">
                                                1) Why do you want to work for
                                                this company?
                                                <br />
                                                2) What can you bring to the
                                                company in your role?
                                            </div>
                                        </VideoWrapper>
                                    )}
                                </Card>
                            )}
                            {(isCognitiveAbilitiesEnabled ||
                                !!hasCognitiveAbilities) && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            Cognitive abilities
                                        </CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    <VideoWrapper>
                                        Your candidates will get a time
                                        questionnaire to learn more about their
                                        cognitive abilities.
                                    </VideoWrapper>
                                </Card>
                            )}
                        </CardsWrapper>
                    </AssessmentSection>
                )}
                {!isScanSetupWizard && (
                    <ReviewSection>
                        <Title>Review and configure your scan</Title>
                        <DetailsBox className="assessment">
                            <Title className="small">Scan name</Title>
                            <Value>{scanName}</Value>
                            <EditIconWrapper
                                onClick={() =>
                                    showChangeAssessmentNameModal(true)
                                }>
                                <EditIcon />
                            </EditIconWrapper>
                            <Title className="small">Language</Title>
                            <Value>
                                {language === 'en-US' ? 'English' : 'Dutch'}
                            </Value>
                            <EditIconWrapper
                                onClick={() => showChangeLanguageModal(true)}>
                                <EditIcon />
                            </EditIconWrapper>
                        </DetailsBox>
                    </ReviewSection>
                )}
                {!isScanSetupWizard && (
                    <AssessmentSection className="no-border-bottom">
                        <DetailsBox>
                            <Title>Scan details</Title>
                            <InfoIconWrapper>
                                <InfoIcon>?</InfoIcon>
                                <InfoTooltip>Details of your scan</InfoTooltip>
                            </InfoIconWrapper>
                        </DetailsBox>
                        <CardsWrapper>
                            {cultureFitIsCompleted && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Cultural Fit</CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    {!!cultureFitVideo && (
                                        <VideoWrapper>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={cultureFitVideo.url}>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {cultureFitVideo.file?.name
                                                        ? cultureFitVideo.file
                                                              ?.name
                                                        : cultureFitVideo.url.substring(
                                                              cultureFitVideo.url.lastIndexOf(
                                                                  '/',
                                                              ) + 1,
                                                          )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                        </VideoWrapper>
                                    )}
                                    {(!cultureFitVideo ||
                                        !isCultureFitVideoEnabled) && (
                                        <VideoWrapper>
                                            Your candidates will get a
                                            questionnaire to learn more about
                                            their work preferences.
                                        </VideoWrapper>
                                    )}
                                </Card>
                            )}
                            {isSpeakingEnabled && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Speaking</CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    {!speakingWrittenQuestions && (
                                        <VideoWrapper>
                                            <UploadTitle className="speaking-title">
                                                Question 1: - Why do you want to
                                                work for this company?
                                            </UploadTitle>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={speakingVideo1?.url}>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {speakingVideo1?.file?.name
                                                        ? speakingVideo1?.file
                                                              ?.name
                                                        : speakingVideo1?.url.substring(
                                                              speakingVideo1.url.lastIndexOf(
                                                                  '/',
                                                              ) + 1,
                                                          )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                            <UploadTitle className="speaking-title">
                                                Question 2: - What can you bring
                                                to the company in your role?
                                            </UploadTitle>
                                            <VideoPreviewWrapper>
                                                <video
                                                    width={'50%'}
                                                    controls
                                                    src={speakingVideo2?.url}>
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                                <UploadTitle className="video-title">
                                                    {speakingVideo2?.file?.name
                                                        ? speakingVideo2?.file
                                                              ?.name
                                                        : speakingVideo2?.url.substring(
                                                              speakingVideo2.url.lastIndexOf(
                                                                  '/',
                                                              ) + 1,
                                                          )}
                                                </UploadTitle>
                                            </VideoPreviewWrapper>
                                        </VideoWrapper>
                                    )}
                                    {speakingWrittenQuestions && (
                                        <VideoWrapper>
                                            <Title className="small">
                                                Your candidates will see the
                                                following two questions:
                                            </Title>
                                            <div className="written-desc">
                                                1) Why do you want to work for
                                                this company?
                                                <br />
                                                2) What can you bring to the
                                                company in your role?
                                            </div>
                                        </VideoWrapper>
                                    )}
                                </Card>
                            )}
                            {(isCognitiveAbilitiesEnabled ||
                                !!hasCognitiveAbilities) && (
                                <Card>
                                    <CardHeader>
                                        <CardTitle>
                                            Cognitive abilities
                                        </CardTitle>
                                        <IconsWrapper>
                                            <CheckMarkIcon>
                                                <TickIcon />
                                            </CheckMarkIcon>
                                        </IconsWrapper>
                                    </CardHeader>
                                    <VideoWrapper>
                                        Your candidates will get a time
                                        questionnaire to learn more about their
                                        cognitive abilities.
                                    </VideoWrapper>
                                </Card>
                            )}
                        </CardsWrapper>
                    </AssessmentSection>
                )}
                {isScanSetupWizard && (
                    <ScanSettingSection>
                        <Title>Settings</Title>
                        <ScanSettingWrapper>
                            <TitleWrapper>
                                <Title className="small">
                                    Activate public registration link
                                </Title>
                                <Desc>
                                    When this is on candidates can register
                                    themselves via a unique URL. You can still
                                    add candidates yourself if this is on.
                                </Desc>
                            </TitleWrapper>
                            <div className="switch-wrapper">
                                <Switch
                                    data-testid="public-link-switch"
                                    height={24}
                                    checked={targetGroup?.is_public}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                    ) =>
                                        handleUpdateTargetGroup(
                                            'is_public',
                                            event.target.checked,
                                        )
                                    }
                                    className="switch"
                                />
                            </div>
                        </ScanSettingWrapper>
                        <hr className="line"></hr>
                        <ScanSettingWrapper>
                            <TitleWrapper>
                                <Title className="small">
                                    Send reports automatically
                                </Title>
                                <Desc>
                                    Candidates receive their reports via emails
                                    automatically when this is on. You can
                                    always send reports manually if this feature
                                    is turned off.
                                </Desc>
                            </TitleWrapper>
                            <div className="switch-wrapper">
                                <Switch
                                    height={24}
                                    checked={targetGroup?.auto_send_reports}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                    ) =>
                                        handleUpdateTargetGroup(
                                            'auto_send_reports',
                                            event.target.checked,
                                        )
                                    }
                                    className="switch"
                                />
                            </div>
                        </ScanSettingWrapper>
                        <hr className="line"></hr>
                        <ScanSettingWrapper>
                            <TitleWrapper>
                                <Title className="small">
                                    Candidate E-mail verification
                                </Title>
                                <Desc>
                                    This extra security feature requires
                                    candidates to verify their email account if
                                    they register via the public registration
                                    URL. It adds an extra step in the candidate
                                    sign up process.
                                </Desc>
                            </TitleWrapper>
                            <InputBox className="switch-wrapper">
                                <Switch
                                    height={24}
                                    disabled={!targetGroup?.is_public}
                                    checked={targetGroup?.verify_emails}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                    ) =>
                                        handleUpdateTargetGroup(
                                            'verify_emails',
                                            event.target.checked,
                                        )
                                    }
                                    className="switch"
                                />
                            </InputBox>
                        </ScanSettingWrapper>
                        <hr className="line"></hr>
                        <ScanSettingWrapper className="with-sub-settings">
                            <TitleWrapper>
                                <Title className="small">
                                    Scan completion notification
                                </Title>
                            </TitleWrapper>
                            <div className="switch-wrapper">
                                <Switch
                                    height={24}
                                    checked={scanCompletionNotification}
                                    onChange={
                                        onChangeScanCompletionNotification
                                    }
                                    className="switch"
                                />
                            </div>
                        </ScanSettingWrapper>
                        <ScanSettingWrapper className="sub-settings">
                            <TitleWrapper>
                                <Desc>
                                    List of emails that will receive
                                    notification when a scan is completed
                                </Desc>
                            </TitleWrapper>
                            <div className="emails-wrapper">
                                <EmailsForm
                                    onEmailsChange={
                                        handleScanCompletionEmailsChange
                                    }
                                    initialEmails={
                                        scanCompletionNotificationEmails
                                    }
                                    emailsDisabled={!scanCompletionNotification}
                                    setToggle={setScanCompletionNotification}
                                />
                            </div>
                        </ScanSettingWrapper>
                        <hr className="line"></hr>
                        <ScanSettingWrapper className="with-sub-settings">
                            <TitleWrapper>
                                <Title className="small">
                                    Report generation notification
                                </Title>
                            </TitleWrapper>
                            <div className="switch-wrapper">
                                <Switch
                                    height={24}
                                    checked={reportGenerationNotification}
                                    onChange={
                                        onChangeReportGenerationNotification
                                    }
                                    className="switch"
                                />
                            </div>
                        </ScanSettingWrapper>
                        <ScanSettingWrapper className="sub-settings">
                            <TitleWrapper>
                                <Desc>
                                    List of emails that will receive
                                    notification when a report is generated
                                </Desc>
                            </TitleWrapper>
                            <div className="emails-wrapper">
                                <EmailsForm
                                    onEmailsChange={
                                        handleReportGenerationEmailsChange
                                    }
                                    initialEmails={
                                        reportGenerationNotificationEmails
                                    }
                                    emailsDisabled={
                                        !reportGenerationNotification
                                    }
                                    setToggle={setReportGenerationNotification}
                                />
                            </div>
                        </ScanSettingWrapper>
                    </ScanSettingSection>
                )}
            </ForthStepWrapper>
            <ScanSetupFooterWrapper>
                <ButtonWrapper>
                    <PrevButton>
                        <AppButton
                            color={'gray'}
                            onClick={() =>
                                goToPrevStep(
                                    isScanSetupWizard && !newAssessmentSection
                                        ? 'SECOND'
                                        : 'THIRD',
                                )
                            }>
                            Previous
                        </AppButton>
                    </PrevButton>
                    <AppButton
                        isDisabled={
                            isScanSetupWizard &&
                            ((scanCompletionNotification &&
                                !isScanCompletionNotificationEmailsValid) ||
                                (reportGenerationNotification &&
                                    !isReportGenerationNotificationEmailsValid))
                        }
                        onClick={goToNextStep}>
                        {isInternal
                            ? 'Create Internal Group'
                            : 'Create Candidate Group'}
                    </AppButton>
                </ButtonWrapper>
            </ScanSetupFooterWrapper>
            <ScanSetupModals
                shouldShowChangeLanguageModal={shouldShowChangeLanguageModal}
                shouldShowChangeTargetGroupNameModal={
                    shouldShowChangeTargetGroupNameModal
                }
                shouldShowChangeDescriptionModal={
                    shouldShowChangeDescriptionModal
                }
                shouldShowChangeAssessmentNameModal={
                    shouldShowChangeAssessmentNameModal
                }
                language={editedLanguage}
                targetGroupName={tGName}
                description={editedDescription}
                assessmentName={assessmentName}
                setLanguage={setEditedLanguage}
                setTargetGroupName={setTGName}
                setDescription={setEditedDescription}
                setAssessmentName={setAssessmentName}
                onDiscardModal={discardModals}
                changeConfirmed={changeConfirmed}
            />
        </>
    );
};

export default ForthStep;
